@use '@angular/material' as mat;
@import './anz-biz-material-theme';
@import './anz-biz-typography';
@import './anz-biz-tenant-variables';

@include mat.core();

$anz-primary: mat.define-palette($primary-theme, 100, 200, 300, 400);
$anz-accent: mat.define-palette($primary-theme, 100, 200, 300, 400);

// The warn palette is optional (defaults to red).
$anz-warn: mat.define-palette($warn-theme);

// Create the theme object (a Sass map containing all of the palettes).
$anz-theme: mat.define-light-theme($anz-primary, $anz-accent, $anz-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($anz-theme);

@import 'rd-global';
@import './anz-biz-fonts';
@import './anz-biz-theme';
@import './anz-biz-theme-overrides';
@import 'third-party-libs/third-party-libs';

$custom-typography: mat.define-typography-config(
  $font-family: 'MyriadPro, Arial, Helvetica, sans-serif',
);

@include mat.all-component-typographies($custom-typography);

body {
  background-color: $white;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body,
button,
input,
.pika-single {
  font-family: MyriadPro, Arial, Helvetica, sans-serif;
}

html,
body {
  height: 100%;
}

/* stylelint-disable max-nesting-depth */
[override] {
  $amount-width: 180px;
  $balance-width: 120px;

  .rd-pt-history-list-desktop .history-list-header {
    span.header-amount {
      width: $amount-width;
    }

    span.header-balance {
      width: $balance-width;
    }
  }

  .rd-pt-history-list-item {
    span.amount {
      @include screen-from-tablet {
        width: $amount-width;
      }
    }

    span.balance {
      @include screen-from-tablet {
        width: $balance-width;
      }
    }
  }

  .rd-gc-review-body {
    .total-charges {
      justify-content: unset;

      @include screen-from-tablet {
        justify-content: space-between;
      }

      .total-charges-value {
        margin-left: 8px;

        @include screen-from-tablet {
          margin-left: 0;
        }
      }
    }
  }

  .rd-gc-success .container {
    padding-bottom: 48px;
    padding-top: 32px;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }

  rd-rcv2-checkout-form {
    .auto-redemption-button {
      .rd-button.outline-primary {
        color: #007dba;

        &:hover {
          color: #007dba;
        }
      }
    }
  }

  .gift-card-denomination-container {
    rd-radio-pills.overflow-pills {
      width: calc(100vw - 16px);
      @apply lg:w-auto;

      .radio-pills-container {
        @apply pr-4 lg:pr-0;
      }
    }
  }

  .rd-merchandise-filter-side-nav-content {
    color: $grey-600;
    .filter-form-header {
      color: $blue-400;
    }
  }

  // Anz Payment form - Anz only

  .rd-payment-form {
    .shared-stripe-form-header {
      @apply lg:px-4;
    }

    .mat-mdc-tab-header {
      border-bottom: 0;
    }

    .mat-mdc-tab-labels {
      @apply lg:px-4;
      background-color: #f0faff;
    }

    .mat-mdc-tab {
      @apply px-0 tracking-normal lg:max-w-[160px];
    }

    .mdc-tab--active.mat-mdc-tab {
      .mdc-tab__text-label > * {
        color: #004165 !important;
        font-weight: 600;
      }
    }

    .mdc-tab-indicator {
      .mdc-tab-indicator__content--underline {
        border-top: 4px solid #004165 !important;
        border-radius: 8px;
      }
    }

    .ElementsApp .InputElement.is-invalid {
      color: #eb1c26;
    }
  }

  .rd-sc-redemption-summary {
    .rd-panel-accordion {
      .mat-expansion-panel-header {
        border-left: 0;
        border-right: 0;
        padding-left: 16px;
        padding-right: 16px;
        height: unset; // Unset to make the height of the header dependent on the padding of the title only
        min-height: unset; // Unset to make the height of the header dependent on the padding of the title only
      }

      .rd-shared-icon {
        align-self: center;
        margin-top: 0;
      }

      .mat-expansion-panel-content {
        border-left: 0;
        border-right: 0;
      }

      mat-panel-title {
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.5rem;
        padding-bottom: 1rem;
        padding-top: 1rem;
      }
    }
  }

  .rd-rcv2-success {
    .redeem-cash-success-details .redeem-amount-container::after {
      content: '';
      display: block;
      margin: 1rem auto;
      max-width: 480px;
      border-top: 1px solid $grey-300;
    }
  }

  .rd-rcv2-checkout-transfer-amount-form {
    .transfer-icon {
      margin: 55px 0 10px;
      .rd-shared-icon {
        .icon-fill {
          fill: #747678;
        }
      }
    }
  }

  .rd-shared-keyword-filter .keyword-search {
    background-size: 16px;
    background-color: white;
    border-color: $grey-500;

    @apply rounded-md;

    // Do not group these placeholder selectors.
    // If one of the selector in the group is invalid,
    // the browser will ignore all other selectors in the group
    &::placeholder {
      opacity: 1;
      color: $grey-600;
    }
    &::-webkit-input-placeholder {
      opacity: 1;
      color: $grey-600;
    }
    &:-moz-placeholder {
      opacity: 1;
      color: $grey-600;
    }
    &::-moz-placeholder {
      opacity: 1;
      color: $grey-600;
    }
    &:-ms-input-placeholder {
      opacity: 1;
      color: $grey-600;
    }
  }

  .rd-anz-points-transaction {
    .rd-pt-category-filter .expander-icon {
      width: 16px;
    }

    .rd-pt-date-range-input .main-input-arrow {
      width: 16px;
    }
  }
}

#auto-redemption-review-confirmation-dialog {
  .button-wrapper {
    flex-direction: column;
    .secondary-button {
      margin-right: 0;
      margin-bottom: 16px;
    }
    @include screen-from-tablet {
      flex-direction: row-reverse;
      .secondary-button {
        margin-left: 10px;
      }
    }
  }
  .rd-anz-button,
  .rd-button {
    @include screen-from-tablet {
      width: 179px;
    }
  }
}

#auto-redemption-cancel-dialog {
  .rd-popup-default-template > .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px 16px 24px 16px;

    @include screen-from-tablet {
      max-height: 75vh;
    }

    .title {
      text-align: center;
      margin-top: 0;
    }

    .button-wrapper {
      @include screen-until-tablet {
        flex-direction: column-reverse;
        height: 136px;
        justify-content: space-between;
      }
    }
  }
}

.radio-pills-container {
  .mat-mdc-radio-button:not(.mdc-radio--disabled) {
    label:hover {
      text-decoration: underline;
    }
  }
}

.order-tabs-container {
  .mdc-tab-indicator .mdc-tab-indicator__content--underline {
    border-color: #004165;
  }
}

// Add to cart dialog & Remove from cart dialog
#gc-add-to-cart-dialog,
#gc-remove-from-cart-dialog {
  .button-wrapper {
    .rd-button {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

/* stylelint-enable max-nesting-depth */

:root {
  --rd-empty-state--title-text-color: #004165;
  --rd-empty-state--text-color: #494949;

  // GC checkout page
  --gc-checkout-amount-form--radio-button-height: 44px;

  // [Shared] Radio Pills component:
  --shared-radio-pills--border-radius-width: 3px;
  --shared-radio-pills--text-color: #007dba;
  --shared-radio-pills--text-selected-color: white;
  --shared-radio-pills--bg-selected-color: #004165;
  --shared-radio-pills--bg-color: white;
  --shared-radio-pills--border-color: #007dba;
  --shared-radio-pills--border-selected-color: transparent;
  --shared-radio-pills--focus-color: #c2ddeb;
  --shared-radio-pills--focus-outline-width: 2px;
  --shared-radio-pills--focus-outline-offset: 1px;

  // PA Statement modal CSS variables
  --pa-statement-modal--text-color: #{$grey-600};
}

.rd-core-main-container {
  .rd-shared-button,
  .button-height {
    height: 44px;
  }

  .promocode-form {
    .rd-shared-button,
    .button-height {
      height: 51px;
    }
  }
}

.rd-shared-button {
  border-radius: 4px;
  &.primary,
  &.secondary {
    &.apply-button,
    &.reset-button {
      width: 160px;
    }
  }

  a:hover .rd-button-text > span {
    text-decoration: underline;
  }
  a.focus-link:focus {
    outline-offset: 0;
  }
  .button-wrapper {
    margin-bottom: 24px;
  }

  .rd-button {
    border-radius: 4px;
    font-weight: 600;
    .btn-icon {
      padding-top: 2px;
      margin-right: 10px;
    }
  }
}

.rd-shared-link {
  .link {
    @apply inline-flex cursor-pointer items-center underline;

    .rd-core-main-container & {
      @apply underline;

      &::after {
        content: none;
      }
    }

    .icon-fill {
      fill: var(--link-color);
    }

    &:hover {
      .icon-fill {
        fill: var(--link-hover-color);
      }
    }
  }

  .rd-shared-icon {
    @apply ml-2 h-3 w-3;
  }

  .button-link {
    @apply appearance-none border-0 bg-transparent p-0 hover:cursor-pointer active:cursor-pointer;
  }
}
